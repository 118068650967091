import React, { useContext, useEffect } from "react";
import { PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { LayoutBild, SEO } from "@components";
import { LoadingContext } from "@context";
import { getAuth } from "firebase/auth";
import { Layout } from "@components/Structural/Landing";
import HigherLanding from "@components/PageLanding/newLanding/Higher/Higher";
import ItemsLanding from "@components/PageLanding/newLanding/ItemsLanding/ItemsLanding";
import ContactLanding from "@components/PageLanding/newLanding/ContactLanding/ContactLanding";
import PartnersLanding from "@components/PageLanding/newLanding/PartnersLanding/PartnersLanding";
import SecurityLanding from "@components/PageLanding/newLanding/Security/Security";

const SaveByRokinPage: React.FC<PageProps> = () => {
	const classes = useStyles();
	const { hideLoader } = useContext(LoadingContext);
	const currentUser = getAuth().currentUser;

	useEffect(() => {
		hideLoader("");
	}, [currentUser]);

	return (
		<>
			<SEO description="Administra tu plata desde un solo lugar. Con Rokin puedes hacer seguimiento de tus gastos, analizar tus hábitos financieros y recibir recomendaciones de ahorro." />
			<LayoutBild key="Bottom" secondLanding>
				<HigherLanding />
				<ItemsLanding />
				<SecurityLanding />
				<ContactLanding noForm />
				<PartnersLanding />
			</LayoutBild>
		</>
	);
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
	top: {
		padding: "1rem 1rem",
		color: theme.palette.primary.main,
		borderRadius: "100%",
		opacity: 0.8,
		"&:hover": {
			opacity: 1,
		},
		position: "fixed",
		right: "1.3rem",
		bottom: "5rem",
		[theme.breakpoints.down(1240)]: {
			right: "0.6rem",
		},
		"& svg": {
			width: "2rem",
			height: "2rem",
		},
	},
	arrow: {
		height: 120,
		position: "absolute",
		color: "white",
		display: "flex",
		margin: "0 auto",
		width: "99.5%",
		zIndex: 9,
		transform: "rotate(1deg)",
	},
	contact: {
		boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
		borderRadius: 60,
	},
}));
